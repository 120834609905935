import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import Header from './Header';
import SeatMap from './SeatMap';
import UpsellDisclaimer from './UpsellDisclaimer';

import SeatDetails from './SeatDetails/SeatDetails';
import ExitRegulations from './ExitRegulations';
import SeatLegend from './SeatLegend';

import { useMediaQuery } from 'react-responsive';
import CategoriesContainer from './CategoriesContainer';

import InfantDetails from './InfantDetails';
import { FooterText } from './FooterText';
import SeatInfantValidationPopup from './SeatInfantValidationPopup';
import SeatGroupValidationPopup from './SeatGroupValidationPopup';
import { isMobile } from 'react-device-detect';
import SeatmapFooter from './SeatmapFooter';

import { showSeatmap } from '../../redux/actions';
import OnCloseModal from './OnCloseModal';

const SeatMapStep = ({
  modal,
  exitModal,
  acceptedExitRegulation,
  upsellModal,
  booleanizedVariants,
  variantsReady,
  selectedSeat,
  showingCategories,
  passengers,
  showSeatmapValidateInfantPopup,
  showSeatmapValidateGroupPopup,
  variants,
  restrictionMap,
  relativeWidthRatio,
  currentSeatmap,
  showSeatmap,
  modalShown,
  isModal,
  isEnhanced,
  showOnClosePopUp,
  seatmapShownEventSent = false,
}) => {
  let {
    test_seatmap_no_close,
    test_seatmap_show_skip,
    test_seatmap_mobile_on_desktop = false,
  } = booleanizedVariants;
  let { currentPassenger = {} } = passengers;
  let convenienceFeeDisplayVariant =
    variants.convenienceFeeConfiguration?.seats?.convenienceFeeDisclaimerText;

  const [showPopupFooterText, setShowPopupFooterText] = useState(false);

  const isMobileSize = useMediaQuery({
    query: `(max-width: ${620 / relativeWidthRatio}px)`,
  });
  const displayTextInFooter = useMediaQuery({
    query: `(min-width: ${1120 / relativeWidthRatio}px)`,
  });

  const triggerTimerID = useRef(0);
  const dismissTimerID = useRef(0);

  useEffect(() => {
    const seatmapReady =
      variantsReady &&
      (!isModal || (isModal && modalShown && isEnhanced !== undefined));
    if (seatmapReady && !seatmapShownEventSent) {
      showSeatmap({
        displayType: isModal ? 'modal' : 'embedded',
        isEnhanced,
        variants: booleanizedVariants,
      });
    }
  }, [
    booleanizedVariants,
    isEnhanced,
    isModal,
    modalShown,
    seatmapShownEventSent,
    showSeatmap,
    variantsReady,
  ]);

  useEffect(() => {
    if (convenienceFeeDisplayVariant !== 'default' && !displayTextInFooter) {
      triggerTimerID.current = setTimeout(
        () => setShowPopupFooterText(true),
        500
      );
      dismissTimerID.current = setTimeout(
        () => setShowPopupFooterText(false),
        10500
      );
    } else {
      clearTimeout(triggerTimerID.current); // clear timers to prevent them from firing
      clearTimeout(dismissTimerID.current);
      setShowPopupFooterText(false);
    }

    return () => {
      clearTimeout(triggerTimerID.current);
      clearTimeout(dismissTimerID.current);
      setShowPopupFooterText(false);
    };
  }, [displayTextInFooter, convenienceFeeDisplayVariant]);

  let currentRestriction = {};
  if (
    Object.keys(restrictionMap).length !== 0 &&
    currentSeatmap in restrictionMap
  ) {
    currentRestriction = restrictionMap[currentSeatmap];
  }

  let brand = undefined;
  if (booleanizedVariants.test_uber_theme === true) brand = 'uber';
  const useMobileDesign =
    isMobile || isMobileSize || test_seatmap_mobile_on_desktop;

  const showLargeGroupValidation =
    currentRestriction.all_or_none === true && showSeatmapValidateGroupPopup;
  // Don't open the on-exit modal if any other popups are open
  const showOnExitModal =
    !showLargeGroupValidation &&
    !showSeatmapValidateGroupPopup &&
    showOnClosePopUp.show;
  return (
    <React.Fragment>
      {upsellModal ? <UpsellDisclaimer /> : null}
      {exitModal && !acceptedExitRegulation ? (
        <ExitRegulations></ExitRegulations>
      ) : null}
      {showOnExitModal && <OnCloseModal />}
      <Header brand={brand} />
      <SeatMap modal={modal} />
      {useMobileDesign ? (
        <SeatDetails
          outsideClickIgnoreClass={'gordian-seat'}
          key="seat-details"
          mobile={true}
        ></SeatDetails>
      ) : null}
      <InfantDetails />
      {showSeatmapValidateInfantPopup && <SeatInfantValidationPopup />}
      {showLargeGroupValidation && <SeatGroupValidationPopup />}
      <SeatmapFooter
        displayTextInFooter={displayTextInFooter}
        variants={variants}
        relativeWidthRatio={relativeWidthRatio}
        test_seatmap_show_skip={test_seatmap_show_skip}
        test_seatmap_no_close={test_seatmap_no_close}
        brand={brand}
      />
      {convenienceFeeDisplayVariant !== 'default' && !displayTextInFooter && (
        <div
          id="priceline-popup-text"
          className="gr-absolute gr-z-50 gr-border-b gr-border-t transition-up-footer-text"
          style={{
            bottom: showPopupFooterText ? 78 : 0,
            left: 0,
            width: '100%',
          }}
        >
          <FooterText
            variants={variants}
            relativeWidthRatio={relativeWidthRatio}
          />
        </div>
      )}
      {currentPassenger.type !== 'infant' &&
      !currentPassenger.infant_on_lap &&
      !showSeatmapValidateInfantPopup &&
      !showSeatmapValidateGroupPopup &&
      ((!isMobileSize && !isMobile) || !selectedSeat) ? (
        <div
          className="gr-absolute gr-mx-2 gr-z-50 transition-up-seat-legend"
          style={{
            bottom: showPopupFooterText ? 140 : 90,
            left: 0,
          }}
        >
          <SeatLegend />
        </div>
      ) : null}
      {showingCategories ? <CategoriesContainer /> : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  selectedSeat: state.selectedSeat,
  showingCategories: state.session.showingCategories,
  exitModal: state.session.exitModal,
  upsellModal: state.session.upsellModal,
  acceptedExitRegulation: state.session.acceptedExitRegulation,
  booleanizedVariants: state.session.variants,
  variantsReady: state.session.variantsReady,
  seatmapShownEventSent: state.session.seatmapShownEventSent,
  passengers: state.passengers,
  showSeatmapValidateInfantPopup: state.session.showSeatmapValidateInfantPopup,
  showSeatmapValidateGroupPopup: state.session.showSeatmapValidateGroupPopup,
  variants: state.session.variants,
  restrictionMap: state.itinerary.restrictionMap,
  relativeWidthRatio: state.session.relativeWidthRatio,
  currentSeatmap: state.itinerary.currentSeatmap,
  modalShown: state.session.modalShown,
  isModal: state.session.isModal,
  isEnhanced: state.session.isEnhanced,
  showOnClosePopUp: state.session.showOnClosePopUp,
});

export default connect(mapStateToProps, { showSeatmap })(
  withLocalize(SeatMapStep)
);

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  compartmentHeight,
  showCategories,
  setVariants,
} from '../../redux/actions';
import MediaQuery from 'react-responsive';
import Compartment from './Compartment';
import SeatDetails from './SeatDetails/SeatDetails';
import { isMobile } from 'react-device-detect';

class Compartments extends Component {
  between = (x, min, max) => {
    return x >= min && x <= max;
  };

  state = {
    parentHeight: 0,
  };

  ref = React.createRef();

  componentDidMount() {
    const compartments = document.getElementById('gordian-compartments');
    if (typeof compartments != 'undefined' && compartments != null) {
      this.props.compartmentHeight(compartments.clientHeight);
    }
  }

  componentDidUpdate() {
    const { compartments, setVariants, variants, currentPassenger, products } =
      this.props;
    const { convenienceFeeConfiguration } = variants;
    // Check if markups are present on seats to show markup verbiage or not
    const isAnyFeeVariantSet =
      convenienceFeeConfiguration &&
      convenienceFeeConfiguration.seats &&
      Object.values(convenienceFeeConfiguration)
        .map((v) => Object.values(v).filter((v2) => v2 && v2 !== 'default'))
        .filter((a) => a.length).length !== 0;
    if (compartments.length > 0 && isAnyFeeVariantSet) {
      // Go through all seats and check if any seat has a markup
      const showMarkupInfo = Object.values(products.seat).some(
        (seat) =>
          seat.price_and_availability[currentPassenger.passenger_id]
            .available &&
          seat.price_and_availability[currentPassenger.passenger_id].price
            .markups[0]?.amount > 0
      );

      if (!showMarkupInfo) {
        // If markups are not present then change variant to 'default' to remove verbiage
        const newVariants = { ...variants };
        newVariants['convenienceFeeConfiguration'] = {
          seats: {
            seatLegendText: 'default',
            seatDetailText: 'default',
            totalPriceText: 'default',
            convenienceFeeDisclaimerText: 'default',
          },
        };
        setVariants(newVariants);
      }
    }
  }

  render() {
    const {
      categories = {},
      compartments,
      currentSeatmap,
      children,
      relativeWidthRatio,
      variants,
    } = this.props;

    const CATEGORIES_DISPLAY_BARRIER = 1119;
    const { test_seatmap_mobile_on_desktop = false } = variants;
    const renderedCompartments = compartments.map((compartment, index) => (
      <Compartment
        categories={categories}
        compartment={compartment}
        currentSeatmapKey={currentSeatmap}
        parentHeight={this.state.parentHeight}
        isLastCompartment={index === compartments.length - 1}
        key={`compartment-${compartment.definition.cabin}`}
      >
        {children}
        {!(test_seatmap_mobile_on_desktop === true) && (
          <MediaQuery minWidth={620 / relativeWidthRatio}>
            {(isLargeScreen) =>
              isLargeScreen && !isMobile ? (
                <SeatDetails key="seat-details" />
              ) : null
            }
          </MediaQuery>
        )}
      </Compartment>
    ));

    return (
      <MediaQuery maxWidth={CATEGORIES_DISPLAY_BARRIER / relativeWidthRatio}>
        {(matches) => {
          const leftMargin = matches ? '' : 'lg:gr-left-250';
          const positionAbsolute = matches
            ? 'gr-absolute gr-top-0 gr-left-0 gr-right-0'
            : '';
          return (
            <div
              ref={this.ref}
              id="gordian-compartments"
              style={{ marginLeft: 360, paddingBottom: 60 }}
              className={`sm:gr-min-w-fit gr-max-w-fit ${positionAbsolute} gr-m-auto gr-min-h-full compartments ${leftMargin} gr-bg-white`}
              dir="ltr"
            >
              {renderedCompartments}
            </div>
          );
        }}
      </MediaQuery>
    );
  }
}

const mapStateToProps = (state) => ({
  flights: state.itinerary.flights,
  categories: state.categories,
  loading: state.session.loading,
  currentSeatmap: state.itinerary.currentSeatmap,
  variants: state.session.variants,
  relativeWidthRatio: state.session.relativeWidthRatio,
  currentPassenger: state.passengers.currentPassenger,
  products: state.products.products,
});

const mapDispatchToProps = { compartmentHeight, showCategories, setVariants };

export default connect(mapStateToProps, mapDispatchToProps)(Compartments);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatPrice, currenciesToRound } from '../../utils/formatPrice';

class Price extends Component {
  render() {
    const {
      hideFree,
      simple,
      prefixText = '',
      isSeat,
      amount,
      currency,
      decimalPlaces,
      tabIndex,
      abbreviatePrice,
      locale,
    } = this.props;

    if (hideFree && amount === 0) {
      return null;
    }

    const localeToUse =
      simple && !currenciesToRound(currency) && locale !== 'ar-SA'
        ? 'en-US'
        : locale;

    let total = formatPrice(
      amount,
      currency,
      decimalPlaces,
      simple || currenciesToRound(currency),
      localeToUse
    );

    if (isSeat && abbreviatePrice && total.includes('$')) {
      // Price abbreviation is meant to replace currency strings such as CA$ for $
      total =
        localeToUse === 'ar-SA'
          ? `${total.split(/\s/)[0]}$`
          : `$${total.split('$')[1]}`;
    }

    return (
      <span tabIndex={tabIndex}>
        {`${prefixText} ${simple ? total.split('.')[0] : total}`.trim()}
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.session.locale,
});

export default connect(mapStateToProps, {})(Price);

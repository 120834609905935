import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Translate } from 'react-localize-redux';
import MediaQuery from 'react-responsive';
import Price from './Price';

function generatePriceTextPostfix(
  convenienceFeeType,
  text,
  shortenFeeText = false
) {
  return convenienceFeeType !== 'default'
    ? !shortenFeeText
      ? `(${text} included)`
      : '(Fees included)'
    : null;
}

export class PaySummary extends Component {
  render() {
    const {
      priceAdjustment = {},
      seatProducts,
      relativeWidthRatio,
      variants,
    } = this.props;

    let convenienceFeeDisplayVariant =
      variants.convenienceFeeConfiguration?.seats?.totalPriceText;

    let { total, currency, decimal_places, count } =
      this.props.basket.seats.reduce(
        (prev, current) => {
          const { passenger_id, product_id } = current;
          const { price_and_availability } = seatProducts[product_id];
          const { price } = price_and_availability[passenger_id];
          const { amount, currency, decimal_places } = price.total;
          prev['total'] = amount + prev['total'];
          prev['currency'] = currency;
          prev['decimal_places'] = decimal_places;
          prev['count'] += 1;
          return prev;
        },
        { total: 0, currency: '', decimal_places: 0, count: 0 }
      );

    const { test_reservation_fee_name = 'Convenience fee' } = variants;
    let totalPriceConvenienceFeeText = (shortenFeeText) => (
      <div className="gr-text-xs">
        {generatePriceTextPostfix(
          convenienceFeeDisplayVariant,
          decodeURI(test_reservation_fee_name),
          shortenFeeText
        )}
      </div>
    );
    if (
      convenienceFeeDisplayVariant &&
      !['variant', 'full', 'default'].includes(convenienceFeeDisplayVariant)
    ) {
      totalPriceConvenienceFeeText = () => (
        <div className="gr-text-xs">{convenienceFeeDisplayVariant}</div>
      );
    }

    const priceFactor = priceAdjustment.factor ?? 1.0;
    const usePriceAdjust = priceFactor !== 1.0;

    const stackedLayout = (
      <MediaQuery maxWidth={850 / relativeWidthRatio}>
        {(shortenFeeText) => {
          const priceMinWidth = shortenFeeText ? 85 : 145;
          return (
            <div
              className="payment-summary gr-text-secondary-text sm:gr-flex-row-reverse sm:gr-mx-4 gr-mr-2"
              style={{ minWidth: priceMinWidth }}
            >
              <div
                className={`gr-block ${
                  usePriceAdjust || convenienceFeeDisplayVariant !== 'default'
                    ? ''
                    : 'gr-pb-2 sm:gr-pb-1'
                } gr-text-title`}
              >
                <Price
                  currency={currency}
                  amount={total * priceFactor}
                  decimalPlaces={decimal_places}
                  abbreviatePrice={convenienceFeeDisplayVariant !== 'default'}
                  simple={convenienceFeeDisplayVariant !== 'default'}
                />
              </div>
              {this.props.priceTextPrepend ?? ''} <Translate id="total" />
              {usePriceAdjust && (
                <div className="gr-text-xs">
                  (inc.{' '}
                  <Price
                    currency={currency}
                    amount={total * (priceFactor - 1.0)}
                    decimalPlaces={decimal_places}
                    abbreviatePrice={convenienceFeeDisplayVariant !== 'default'}
                    simple={convenienceFeeDisplayVariant !== 'default'}
                  />{' '}
                  {`${priceAdjustment.name}`})
                </div>
              )}
              {totalPriceConvenienceFeeText(shortenFeeText)}
            </div>
          );
        }}
      </MediaQuery>
    );

    return count !== 0 && currency ? stackedLayout : null;
  }
}

const mapStateToProps = (state) => ({
  basket: state.basket,
  variants: state.session.variants,
  categories: state.categories,
  seatProducts: state.products.products.seat,
  priceAdjustment: state.session.priceAdjustment,
  priceTextPrepend: state.session.priceTextPrepend,
  relativeWidthRatio: state.session.relativeWidthRatio,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaySummary);

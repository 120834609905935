// The intention here is to transpile the old (deprecated) convenience_fee_display_variant variant into
// the new convenienceFeeConfiguration object to keep support for the deprecated functionality.
// The new object values will be used both as the old convenienceFeeDisplayVariant controller values
// ("defaul", "variant" and "full") and as a full text to aid in internationalization of the disclaimers.

const calculateConvenienceFeeVariants = (variants) => {
  //because %{markup} may cause issues while decoding
  let decodedVariant;

  const asString = variants.convenienceFeeConfiguration
    ?.toString()
    .replace(/%({[^}]*})/g, 'PLACEHOLDER_$1');

  const safeEncodedString =
    asString === '[object Object]'
      ? JSON.stringify(variants.convenienceFeeConfiguration).replace(
          /%({[^}]*})/g,
          'PLACEHOLDER_$1'
        )
      : asString;
  if (safeEncodedString) {
    const decodedString = decodeURI(safeEncodedString);
    decodedVariant = JSON.parse(
      decodedString.replace(/PLACEHOLDER_({[^}]*})/g, '%$1')
    );
  }
  variants.convenienceFeeConfiguration = decodedVariant ?? {};
  variants.convenienceFeeConfiguration.seats = decodedVariant?.seats ?? {};

  const convenienceFeeConfigurationFields = [
    'seatLegendText',
    'seatDetailText',
    'totalPriceText',
    'convenienceFeeDisclaimerText',
  ];

  // convenience_fee_display_variant is deprecated, but it is still supported
  let variantToUse = 'default';
  if (variants?.convenience_fee_display_variant) {
    const validVariants = ['variant', 'full', 'default'];
    const formattedVariant = variants.convenience_fee_display_variant
      .toString()
      .toLowerCase();
    const isValidVariant = validVariants.includes(formattedVariant);
    variantToUse = isValidVariant ? formattedVariant : 'default';
  }

  // If any of the convenienceFeeConfiguration fields is not set, we use the convenience_fee_display_variant
  // or a default value
  for (const convenienceFeeConfigurationField of convenienceFeeConfigurationFields) {
    variants.convenienceFeeConfiguration.seats[
      convenienceFeeConfigurationField
    ] =
      variants.convenienceFeeConfiguration.seats[
        convenienceFeeConfigurationField
      ] || variantToUse;
  }

  return variants;
};

module.exports = {
  calculateConvenienceFeeVariants,
};

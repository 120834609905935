import React from 'react';
// import { ReactComponent as Accessible } from '../../../svg/accessible.svg';
// import { ReactComponent as Laptop } from '../../../svg/laptop.svg';
// import { ReactComponent as Lock } from '../../../svg/lock.svg';
// import { ReactComponent as LongBed } from '../../../svg/long_bed.svg';
import { ReactComponent as GenericItem } from '../../../svg/generic-item.svg';

const BenefitIcon = ({ _benefitDescription, className }) => {
  // We use a generic icon for everything for now
  return (
    <GenericItem
      className={className}
      style={{ minWidth: '8', minHeight: '8' }}
    />
  );
  // We can add this again if Allegris ends up launching and using custom items
  // const lowerCaseBenefitDescription = benefitDescription.toLowerCase();
  // if (lowerCaseBenefitDescription.includes('wheelchair')) {
  //   return <Accessible className={className} />;
  // }
  // if (lowerCaseBenefitDescription.includes('monitor')) {
  //   return <Laptop className={className} />;
  // }
  // if (lowerCaseBenefitDescription.includes('privacy')) {
  //   return <Lock className={className} />;
  // }
  // if (lowerCaseBenefitDescription.includes('space')) {
  //   return <LongBed className={className} />;
  // }
};

export function Benefits(props) {
  const { descriptions, isCategoryLegend = false } = props;

  const textStyle = 'gr-text-xs gr-text-black gr-font-medium';

  const texts = isCategoryLegend ? descriptions : descriptions?.texts;

  const benefitsList = [];

  texts.forEach((descriptionText, i) => {
    const benefitDescription = isCategoryLegend
      ? descriptionText
      : descriptionText.text;
    if (benefitDescription !== '') {
      benefitsList.push(
        <div
          className={`gr-flex gr-leading-3 ${textStyle} gr-items-center gr-mt-2 gr-break-all`}
          key={i}
        >
          <br />
          <BenefitIcon
            benefitDescription={benefitDescription}
            className={'gr-mr-2 gr-my-1'}
          />{' '}
          {benefitDescription}
        </div>
      );
    }
  });
  return <div>{benefitsList}</div>;
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import SeatSelectButton from '../SeatSelectButton';
import Price from '../Price';
import { getDisplayPrice } from '../../../utils/getDisplayPrice';
import { Translate } from 'react-localize-redux';

import onClickOutside from 'react-onclickoutside';

import { ReactComponent as Close } from '../../../svg/close.svg';
import FocusTrap from 'focus-trap-react';
import MediaQuery from 'react-responsive';
import Amenities from './Amenities';

import {
  acceptSeat,
  removeSeatFromBasket,
  updateSelectedSeat,
  nextPassenger,
  setCurrentPassenger,
  confirmBasket,
} from '../../../redux/actions';
import { isMobile } from 'react-device-detect';
import { rtlLangs } from '../../../translations/rtl-langs';
import { DetailsContainer } from './DetailsContainer';
import { Benefits } from './Benefits';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export class SeatDetails extends Component {
  state = {
    catergory: {},
    selected: false,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleClickOutside = (evt) => {
    const id = evt.target.id;
    const className = evt.target.className;
    if (
      id === 'select-seat' ||
      className.includes('gr-exit-regulations') ||
      id === 'accept_exit_regulations' ||
      className.includes('gr-upsell_disclaimer') ||
      id === 'gr_upsell_disclaimer_accept_button'
    ) {
      return;
    } else {
      this.props.updateSelectedSeat(null);
    }
  };

  render() {
    const {
      basket,
      selectedSeat,
      selectedProduct,
      passengers = {},
      categories,
      currentSeatmap,
      acceptSeat,
      removeSeatFromBasket,
      updateSelectedSeat,
      nextPassenger,
      setCurrentPassenger,
      mobile = false,
      priceAdjustment = {},
      booleanizedVariants = {},
      variants,
      confirmBasket,
      locale,
      descriptions,
      useEnhancedDetails,
    } = this.props;

    const seatDescription =
      selectedSeat && descriptions
        ? descriptions.find(
            (d) => d.description_id === selectedSeat.description_id
          )
        : null;

    const {
      test_seatmap_one_click_desktop = true,
      test_seatmap_one_click_mobile = false,
      test_confirm_on_click = false,
    } = variants;

    if (!selectedSeat) {
      return null;
    }

    const {
      image_url = '',
      image_urls = [],
      display_name,
      description,
      benefits = [],
    } = categories[currentSeatmap][selectedSeat.seat_category_id];
    const imagesToDisplay = (
      image_urls?.length ? image_urls : [image_url]
    ).filter((img) => img);

    const { currentPassenger, listOfPassenger } = passengers;

    let passenger = currentPassenger;

    const [selected = null] = basket.seats.filter(
      (seat) =>
        seat.segment_id === currentSeatmap && seat.seat === selectedSeat.seat
    );

    if (selected) {
      [passenger] = listOfPassenger.filter(
        (passenger) => passenger.passenger_id === selected.passenger_id
      );
    }

    if (
      !selectedProduct.price_and_availability[passenger.passenger_id].available
    ) {
      document.removeEventListener('click', this.handleOutsideClick, false);
      return null;
    }

    let passenger_name = `${passenger.first_names} ${passenger.surname}`;

    const lastInitial =
      passenger?.surname?.length > 0
        ? `${passenger.surname.substring(0, 1)}.`
        : '';

    document.addEventListener('click', this.handleOutsideClick, false);

    const { price_and_availability } = selectedProduct;
    const { seat, characteristics = [] } = selectedSeat;

    const { price } = price_and_availability[passenger.passenger_id];
    const { base, total, markups = [], taxes = [], fees = [] } = price;
    const { currency, decimal_places } = total;

    const priceFactor = priceAdjustment.factor ?? 1.0;
    const usePriceAdjust = priceFactor !== 1.0;
    const { test_reservation_fee_name = 'Convenience Fee' } =
      booleanizedVariants;
    const markupAmount = markups.reduce(
      (accumulator, curr) => accumulator + curr.amount,
      0
    );
    let convenienceFeeDisplayVariant =
      variants.convenienceFeeConfiguration?.seats?.seatDetailText;
    const hasMarkup = !!markupAmount;
    const useReservationFeeName =
      !!test_reservation_fee_name &&
      hasMarkup &&
      convenienceFeeDisplayVariant !== 'default';

    let reservationFeeTextPrefix = 'Includes ';
    let reservationFeeTextSuffix = '';
    if (convenienceFeeDisplayVariant === 'variant') {
      reservationFeeTextPrefix = '';
      reservationFeeTextSuffix = ' added at checkout';
    } else if (convenienceFeeDisplayVariant === 'full') {
      reservationFeeTextPrefix = '';
      reservationFeeTextSuffix = '';
    }
    // we only use this for Priceline, so it's ok that it only works for USD
    const markupIsMoreThanOneDollar =
      currency !== 'USD' || markupAmount / Math.pow(10, decimal_places) >= 1;

    const isSimple =
      convenienceFeeDisplayVariant !== 'default' && markupIsMoreThanOneDollar;
    let reservationFee = (
      <div className="gr-flex">
        <span>
          {reservationFeeTextPrefix}
          <Price
            currency={currency}
            amount={markupAmount}
            decimalPlaces={decimal_places}
            tabIndex={0}
            abbreviatePrice={convenienceFeeDisplayVariant !== 'default'}
            simple={isSimple}
          />{' '}
          {decodeURI(test_reservation_fee_name)}
          {reservationFeeTextSuffix}
        </span>
      </div>
    );
    if (
      convenienceFeeDisplayVariant &&
      !['variant', 'full', 'default'].includes(convenienceFeeDisplayVariant)
    ) {
      const seatDetailTextParts =
        convenienceFeeDisplayVariant.split('%{markup}');
      reservationFee = (
        <div className="gr-flex">
          <span>
            {seatDetailTextParts.map((part, index) => (
              <React.Fragment key={index}>
                {part}
                {index < seatDetailTextParts.length - 1 && (
                  <Price
                    currency={currency}
                    amount={markupAmount}
                    decimalPlaces={decimal_places}
                    tabIndex={0}
                    abbreviatePrice={convenienceFeeDisplayVariant !== 'default'}
                    simple={isSimple}
                  />
                )}
              </React.Fragment>
            ))}
          </span>
        </div>
      );
    }

    const isEnhancedSeatDetail = useEnhancedDetails;
    const current_passenger = this.props.passengers.currentPassenger;
    const selected_seat = this.props.selectedSeat.seat;
    const ariaPassenger = `${current_passenger.first_names} ${current_passenger.surname}`;

    const SeatButton = (
      <SeatSelectButton
        id="select-seat"
        handleClick={() => {
          if (selected) {
            removeSeatFromBasket({
              selectedSeat,
              currentSeatmap,
            });
            test_confirm_on_click && confirmBasket();
            setCurrentPassenger(passenger);
          } else {
            acceptSeat({
              currentSeatmap,
              selectedSeat,
              currentPassenger: passenger,
              selectedProduct,
            });
            nextPassenger(passenger);
          }
          updateSelectedSeat(null);
        }}
        selected={selected}
        aria_passenger={ariaPassenger}
        aria_seat={selected_seat}
      >
        {selected ? (
          <Translate id="remove_seat" />
        ) : (
          <Translate id="select_seat" />
        )}
      </SeatSelectButton>
    );
    const seatDetailsPadding = isEnhancedSeatDetail
      ? 'gr-pb-4'
      : 'gr-p-3 gr-py-4';
    const desktopSeatDetailsClasses = useReservationFeeName
      ? 'seat-detail gr-px-3 gr-pt-4 gr-pb-2'
      : `seat-detail ${seatDetailsPadding}`;

    const airlineBasePrice =
      base.amount +
      taxes.reduce((accumulator, curr) => accumulator.amount + curr, 0) +
      fees.reduce((accumulator, curr) => accumulator.amount + curr, 0);
    const displayPrice = getDisplayPrice(
      convenienceFeeDisplayVariant,
      airlineBasePrice,
      total.amount
    );

    const test_seatmap_one_click = isMobile
      ? test_seatmap_one_click_mobile
      : test_seatmap_one_click_desktop;

    const DesktopSeatDetails = (
      <div id="desktop-seat-details" className={desktopSeatDetailsClasses}>
        <div className="gr-flex gr-justify-between gr-mb-2">
          <h3
            className="gr-text-base gr-text-title"
            tabIndex={0}
            dir={rtlLangs.includes(locale) ? 'rtl' : 'ltr'}
          >
            <Translate id="seat" />
            &nbsp;
            {seat}
          </h3>
          {!test_seatmap_one_click ? (
            <button
              id="close-seat-details"
              aria-label={`Close details for seat ${selected_seat} for ${ariaPassenger}`}
              onClick={(e) => {
                e.preventDefault();
                updateSelectedSeat(null);
              }}
            >
              <Close
                alt="close"
                className="gr-cursor-pointer"
                style={{ width: 14, height: 14 }}
              />
            </button>
          ) : null}
        </div>
        <div className="gr-flex gr-justify-between gr-items-center gr-mt-2 gr-mb-4 gr-text-gray-700">
          <span tabIndex={0}>{passenger_name}</span>
          <span>
            <Price
              currency={currency}
              amount={displayPrice * priceFactor}
              decimalPlaces={decimal_places}
              tabIndex={0}
            />
            {usePriceAdjust && (
              <div className="gr-text-xs">
                (inc.{' '}
                <Price
                  currency={currency}
                  amount={displayPrice * (priceFactor - 1.0)}
                  decimalPlaces={decimal_places}
                  tabIndex={0}
                />{' '}
                {`${priceAdjustment.name}`})
              </div>
            )}
          </span>
        </div>
        {!test_seatmap_one_click_desktop ? SeatButton : null}
        {useReservationFeeName && (
          <div
            className="gr-flex gr-pt-1 gr-justify-center gr-text-gray-500"
            style={{ fontSize: '11.2px' }}
          >
            {reservationFee}
          </div>
        )}
        <Amenities characteristics={characteristics} desktop={true} />
      </div>
    );

    const EnhacedTitleSection = (
      <div>
        <div className=" gr-text-black gr-font-inter gr-font-bold">
          <div className="gr-flex gr-justify-between">
            <h3 tabIndex={0} dir={rtlLangs.includes(locale) ? 'rtl' : 'ltr'}>
              <Translate id="seat" />
              &nbsp;
              {seat}
            </h3>
            <div>
              <h3 className="gr-mb-2 gr-text-blue-primary">{display_name}</h3>
            </div>
            {!test_seatmap_one_click ? (
              <button
                id="close-seat-details"
                aria-label={`Close details for seat ${selected_seat} for ${ariaPassenger}`}
                onClick={(e) => {
                  e.preventDefault();
                  updateSelectedSeat(null);
                }}
              >
                <Close
                  alt="close"
                  className="gr-cursor-pointer"
                  style={{ width: 14, height: 14 }}
                />
              </button>
            ) : null}
          </div>
        </div>
        <div className="gr-flex gr-justify-between gr-items-center gr-text-gray-500">
          <span tabIndex={0}>{passenger_name}</span>
          <span>
            <Price
              currency={currency}
              amount={displayPrice * priceFactor}
              decimalPlaces={decimal_places}
              tabIndex={0}
            />
            {usePriceAdjust && (
              <div className="gr-text-xs">
                (inc.{' '}
                <Price
                  currency={currency}
                  amount={displayPrice * (priceFactor - 1.0)}
                  decimalPlaces={decimal_places}
                  tabIndex={0}
                />{' '}
                {`${priceAdjustment.name}`})
              </div>
            )}
          </span>
        </div>
      </div>
    );
    const hasSeatDescriptions = !!seatDescription?.texts?.some((texts) => {
      return texts.text && texts.text !== '';
    });

    const DesktopEnhancedSeatDetails = (
      <div id="desktop-seat-details" className={desktopSeatDetailsClasses}>
        {imagesToDisplay?.length > 0 ? (
          <Carousel
            interval={2000}
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            showArrows={!test_seatmap_one_click_desktop}
            showIndicators={false}
            showStatus={false}
            className={'gr-mb-2 gr-mx-0 gr-h-32 gr-overflow-hidden'}
            width={'280px'}
          >
            {imagesToDisplay.map((img_url, index) => (
              <div key={index}>
                <img alt={''} src={img_url} style={{ objectFit: 'cover' }} />
              </div>
            ))}
          </Carousel>
        ) : (
          <div className="gr-pb-2"> </div>
        )}
        <div className="gr-px-3 gr-pb-3">{EnhacedTitleSection}</div>
        {hasSeatDescriptions ? <div className="gr-border-b-2"></div> : null}
        <div className="gr-px-3">
          {!test_seatmap_one_click_desktop ? SeatButton : null}
          {useReservationFeeName && (
            <div
              className="gr-flex gr-pt-1 gr-justify-center gr-text-gray-500"
              style={{ fontSize: '11.2px' }}
            >
              {reservationFee}
            </div>
          )}
          <div>
            <div className="gr-mt-2 gr-text-gray-500 gr-hidden">
              {description}
            </div>
          </div>
          {seatDescription ? <Benefits descriptions={seatDescription} /> : null}
          <Amenities characteristics={characteristics} desktop={true} />
        </div>
      </div>
    );

    const hasSeatBenefits = benefits?.length > 0;

    const benefitsList = (benefits, limit) => {
      const list = [];
      benefits.forEach((benefit, index) => {
        list.push(
          <li id={`seat_details_benefit_${index}`} className="" key={index}>
            {benefit}
          </li>
        );
      });
      return (
        <ul
          id="seat-benefits-list"
          className={`gr-list-disc gr-ml-1 gr-pl-1 gr-mt-1`}
        >
          {limit ? list.slice(0, limit) : list}
        </ul>
      );
    };

    const mobileSeatDetailsClass = 'gr-flex gr-flex-col gr-p-3 gr-py-2';
    const DenseMobileSeatDetails = (
      <div id="dense-mobile-seat-details" className={mobileSeatDetailsClass}>
        <div className="seat-detail gr-flex gr-items-center gr-justify-between gr-py-2">
          <div className="gr-flex gr-flex-col gr-justify-start gr-w-1/3">
            <span className="gr-flex gr-w-full gr-font-semibold gr-text-title">
              <Price
                currency={currency}
                amount={displayPrice * priceFactor}
                decimalPlaces={decimal_places}
              />
            </span>
            {usePriceAdjust && (
              <div className="gr-text-xs">
                (inc.{' '}
                <Price
                  currency={currency}
                  amount={displayPrice * (priceFactor - 1.0)}
                  decimalPlaces={decimal_places}
                />{' '}
                {`${priceAdjustment.name}`})
              </div>
            )}
            <div className="gr-flex gr-pr-2">
              <span className="gr-text-secondary-text gr-truncate">
                {passenger.first_names}
              </span>
              <span className="gr-text-secondary-text gr-flex-shrink-0 gr-mx-1">
                {lastInitial}
              </span>
            </div>
            <span className="gr-flex gr-text-secondary-text">{seat} </span>
            {passenger.infant_on_lap && (
              <div className="gr-flex gr-text-xs gr-bg-gray-500 gr-text-white gr-px-1 gr-mr-1 gr-my-1 gr-rounded-sm">{`+ infant`}</div>
            )}
          </div>
          <div
            className={`gr-flex gr-flex-col gr-border-solid gr-border-l-2 gr-border-grey gr-w-full`}
          >
            <div className="gr-flex gr-flex-row gr-items-center">
              <div
                className={`gr-flex gr-flex-col ${
                  test_seatmap_one_click_mobile ? '' : 'gr-w-2/3'
                } gr-pl-4 gr-pr-2 gr-justify-start`}
              >
                <span className="gr-flex gr-w-full gr-text-title">
                  {display_name}
                </span>
                <span className="gr-flex gr-w-full gr-text-secondary-text">
                  {hasSeatBenefits ? benefitsList(benefits) : description}
                </span>
              </div>
              {!test_seatmap_one_click_mobile && (
                <div className="gr-flex gr-flex-auto">{SeatButton}</div>
              )}
            </div>

            {useReservationFeeName && (
              <div
                className="gr-flex gr-justify-start gr-pt-1 gr-pl-4 gr-text-gray-500"
                style={{ fontSize: '10.4px' }}
              >
                {reservationFee}
              </div>
            )}
          </div>

          {test_seatmap_one_click_mobile && (
            <div
              className="gr-ml-2"
              style={{ position: 'absolute', top: '15%', right: '3.5%' }}
            >
              <button
                id="close-seat-details"
                aria-label={`Close details for seat ${selected_seat} for ${ariaPassenger}`}
                onClick={(e) => {
                  e.preventDefault();
                  updateSelectedSeat(null);
                }}
              >
                <Close
                  alt="close"
                  className="gr-cursor-pointer"
                  style={{ width: 14, height: 14 }}
                />
              </button>
            </div>
          )}
        </div>
        <Amenities characteristics={characteristics} />
      </div>
    );

    return (
      <MediaQuery minWidth={620 / this.props.relativeWidthRatio}>
        {(isLargeScreen) => (
          <FocusTrap
            focusTrapOptions={{ initialFocus: '#seat-details-container' }}
            // Add here when adding additional modals, else, the outside click handler on this component will fire.
            active={
              !mobile &&
              !isMobile &&
              isLargeScreen &&
              !this.props.exitModal &&
              !this.props.upsellModal &&
              !test_seatmap_one_click
            }
          >
            <DetailsContainer
              current_passenger={current_passenger}
              selected_seat={selected_seat}
              mobile={mobile || isMobile}
              isEnhancedSeatDetail={isEnhancedSeatDetail}
              {...this.props.position}
            >
              {image_url ? (
                <img
                  className="gr-w-full gr-object-cover gr-rounded-tl gr-rounded-tr border-0"
                  alt={''}
                  src={image_url}
                ></img>
              ) : null}

              {mobile || isMobile
                ? DenseMobileSeatDetails
                : isEnhancedSeatDetail
                  ? DesktopEnhancedSeatDetails
                  : DesktopSeatDetails}
            </DetailsContainer>
          </FocusTrap>
        )}
      </MediaQuery>
    );
  }
}

const mapStateToProps = (state) => ({
  basket: state.basket,
  categories: state.categories,
  parentHeight: state.session.parentHeight,
  currentSeatmap: state.itinerary.currentSeatmap,
  useEnhancedDetails:
    state.itinerary.seatmapsBySegment[state.itinerary.currentSeatmap]
      ?.useEnhancedDetails,
  passengers: state.passengers,
  position: state.seatDetails.seat_details_position,
  selectedSeat: state.selectedSeat,
  selectedProduct:
    state.products.products?.seat?.[state.selectedSeat?.product_id] || null,
  exitModal: state.session.exitModal,
  booleanizedVariants: state.session.variants,
  priceAdjustment: state.session.priceAdjustment,
  reservationFeeName: state.session.reservationFeeName,
  upsellModal: state.session.upsellModal,
  variants: state.session.variants,
  relativeWidthRatio: state.session.relativeWidthRatio,
  locale: state.session.locale,
  descriptions:
    state.itinerary.seatmapsBySegment?.[state.itinerary.currentSeatmap]
      ?.descriptions,
});

const mapDispatchToProps = {
  acceptSeat,
  updateSelectedSeat,
  removeSeatFromBasket,
  nextPassenger,
  setCurrentPassenger,
  confirmBasket,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(SeatDetails));

import React from 'react';
import CabinBagIcon from './CabinBag';
import CheckedBagIcon from './CheckedBag';
import { WeightText } from './WeightText';
import { formatPrice } from '../../utils/formatPrice';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { BagDetails, Price, Theme } from './CommonTypes';

function DesktopPassengerRowBagSection({
  bagDetails,
  totalPrice,
  minimumPrice,
  theme,
}: {
  bagDetails: BagDetails;
  totalPrice: number;
  minimumPrice: Price;
  theme: Theme;
}) {
  return (
    <div
      className="gr-py-4 gr-px-2 gr-mx-4 gr-my-3 gr-w-1/3"
      style={{ minWidth: '140px', maxWidth: '250px' }}
    >
      <div className="gr-flex gr-mb-2 gr-items-center">
        {bagDetails.checked ? (
          <div style={{ height: '33px', width: '23px' }}>
            <CheckedBagIcon />
          </div>
        ) : (
          <div style={{ height: '38px', width: '23px' }}>
            <CabinBagIcon />
          </div>
        )}
        <span className="gr-bag-title-name-text gr-text-xl gr-ml-3">
          {bagDetails.display_name}
        </span>
      </div>
      <WeightText bagDetails={bagDetails} />
      {totalPrice === 0 ? (
        <p
          className="gr-bag-starting-price-text gr-text-xs gr-not-italic gr-leading-normal gr-font-bold gr-my-0 gr-self-center"
          style={{ color: theme?.bags?.fromPriceText ?? '#846be8' }}
        >
          <Translate id={'from'}>From</Translate>{' '}
          {formatPrice(
            minimumPrice.amount,
            minimumPrice.currency,
            minimumPrice.decimal_places
          )}
        </p>
      ) : (
        <p className="gr-bag-total-price-text gr-leading-normal gr-text-gray-550 gr-text-xs gr-font-normal gr-not-italic gr-my-0 gr-self-center">
          <Translate id={'total'}>Total price</Translate>{' '}
          {formatPrice(
            totalPrice,
            minimumPrice.currency,
            minimumPrice.decimal_places
          )}
        </p>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  theme: state.session.theme,
});

export default connect(mapStateToProps)(DesktopPassengerRowBagSection);

import React, { Component } from 'react';
import Category from './Category';
import {
  CategoryInterfaces,
  PassengerInterfaces,
  ThemeInterfaces,
} from './CommonInterfaces';
import { connect } from 'react-redux';
import { getDisplayPrice } from '../../utils/getDisplayPrice';
import { formatPrice } from '../../utils/formatPrice';

interface CategoriesListProps {
  currentSeatmap: string;
  categories: CategoryInterfaces.Categories;
  simpleVariant: boolean;
  theme: ThemeInterfaces.Theme;
  hideDetails: boolean;
  priceAdjustment: { [key: string]: any };
  variants: { [variantKey: string]: any };
  currentPassenger: PassengerInterfaces.Passenger;
  price_range: any;
}

const Unavailable = (simpleVariant: boolean, theme: ThemeInterfaces.Theme) => (
  <Category
    key="unavailable_key"
    type="unavailable"
    fill={simpleVariant ? theme.seats['not_available'].fill : null}
    borderColor={
      simpleVariant ? theme.seats['not_available'].borderColor : null
    }
  />
);

class CategoriesList extends Component<CategoriesListProps> {
  render(): JSX.Element[] {
    const {
      currentSeatmap,
      categories,
      simpleVariant,
      theme,
      hideDetails = false,
      priceAdjustment = {},
      variants,
      currentPassenger,
    } = this.props;
    const listOfCategories: JSX.Element[] = [];

    if (categories) {
      const seatmapCategories = categories[currentSeatmap];
      // This variable will be used both as the old convenienceFeeDisplayVariant controller and
      // as a full text to aid in internationalization of the disclaimers.
      // It's needed to support both the old behavior and the new more customizable
      // texts some partners need.
      const seatLegendText =
        variants.convenienceFeeConfiguration?.seats?.seatLegendText;
      const priceFactor = priceAdjustment.factor ?? 1.0;

      let displayPriceBelow = false;
      for (let key in seatmapCategories) {
        const categoryProps = { ...seatmapCategories[key] };
        if (categoryProps.price_range[currentPassenger.passenger_id]) {
          const { start_price: startPrice, end_price: endPrice } = categoryProps
            .price_range[currentPassenger.passenger_id] as any;

          const startDisplayPrice = getDisplayPrice(
            seatLegendText,
            startPrice.base_price,
            startPrice.total
          );
          const endDisplayPrice = getDisplayPrice(
            seatLegendText,
            endPrice.base_price,
            endPrice.total
          );

          const startPriceAsString = formatPrice(
            startDisplayPrice * priceFactor,
            startPrice.currency,
            startPrice.decimal_places
          );
          const endPriceAsString = formatPrice(
            endDisplayPrice * priceFactor,
            endPrice.currency,
            endPrice.decimal_places
          );

          if (
            startPriceAsString === endPriceAsString &&
            startPriceAsString.length > 12
          ) {
            displayPriceBelow = true;
            break;
          } else if (
            startPriceAsString !== endPriceAsString &&
            startPriceAsString.length + endPriceAsString.length > 12
          ) {
            displayPriceBelow = true;
            break;
          }
        }
      }
      for (let key in seatmapCategories) {
        listOfCategories.push(
          <Category
            key={key}
            seatLegendText={seatLegendText}
            {...seatmapCategories[key]}
            hideDetails={hideDetails}
            displayPriceBelow={displayPriceBelow}
          />
        );
      }
    }

    listOfCategories.push(Unavailable(simpleVariant, theme));

    return listOfCategories;
  }
}

const mapStateToProps = (state: any) => ({
  currentPassenger: state.passengers.currentPassenger,
  priceAdjustment: state.session.priceAdjustment,
  variants: state.session.variants,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);

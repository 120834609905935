import { handleAction, handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { initialSessionState, initialItineraryState } from '../initialState';

import { UPDATE_SELECTED_SEAT, SET_BAGGAGE_DATA } from '../actions';

import { basket } from './basket';
import { seatDetails } from './seatDetails';
import { categories } from './categories';

import { localizeReducer } from 'react-localize-redux';

const baggageJSON = handleAction(
  SET_BAGGAGE_DATA,
  (state, action) => {
    return {
      ...state,
      data: action.payload,
    };
  },
  {
    data: {},
  },
);

const selectedSeat = handleAction(
  UPDATE_SELECTED_SEAT,
  (state, action) => {
    return action.payload;
  },
  null,
);

const itinerary = handleActions(
  {
    SET_ITINERARY: (state, action) => {
      const { segmentIndex, seatmapsBySegment } = action.payload;
      const flights = action.payload.legs.map(
        ({ segment_id, ...information }) => {
          const seatmap = seatmapsBySegment[segment_id];
          const { available = true, decks = [] } = seatmap;
          const [wings = {}] = decks.map((decks) => decks.wings);
          return { available, ...information, wings, segment_id };
        },
      );

      let currentSeatmap;

      if (
        segmentIndex &&
        !isNaN(segmentIndex) &&
        flights[segmentIndex] &&
        flights[segmentIndex].available
      ) {
        currentSeatmap = flights[segmentIndex].segment_id;
      } else {
        currentSeatmap =
          flights[flights.findIndex((flight) => flight.available)].segment_id;
      }

      return {
        ...state,
        flights,
        currentSeatmap,
        seatmapsBySegment,
      };
    },
    SET_FLIGHTS: (state, action) => {
      const { segmentIndex } = action.payload;
      const flights = action.payload.legs.map(
        ({ segment_id, ...information }) => {
          const available = true;
          return { available, ...information, segment_id };
        },
      );

      let currentSeatmap;

      if (
        segmentIndex &&
        !isNaN(segmentIndex) &&
        flights[segmentIndex] &&
        flights[segmentIndex].available
      ) {
        currentSeatmap = flights[segmentIndex].segment_id;
      } else {
        currentSeatmap =
          flights[flights.findIndex((flight) => flight.available)].segment_id;
      }

      return {
        ...state,
        flights,
        currentSeatmap,
      };
    },
    SET_CURRENT_SEAT_MAP: (state, action) => ({
      ...state,
      currentSeatmap: action.payload,
    }),
    SET_BAGGAGE_JOURNEYS: (state, action) => ({
      ...state,
      baggageJourneys: action.payload,
    }),
    SET_CURRENT_BAGGAGE_JOURNEY: (state, action) => ({
      ...state,
      currentBaggageJourney: action.payload,
    }),
    NEXT_SEATMAP: (state, action) => {
      return {
        ...state,
        currentSeatmap: action.payload,
      };
    },
    ADD_UPSELL_OPTIONS: (state, action) => {
      const { id, upsellOptions } = action.payload;
      const currentOptionsMap = state.upsellOptionsMap;
      // Map each name to the option for easier retrieval later.
      let mapping = {};
      for (const opt of upsellOptions) {
        if (opt?.upsell_parameters?.cabin) {
          mapping = {
            ...mapping,
            [opt.upsell_parameters.cabin]: opt,
          };
        }
      }

      return {
        ...state,
        upsellOptionsMap: {
          ...currentOptionsMap,
          [id]: mapping,
        },
      };
    },
    ADD_RESTRICTIONS: (state, action) => {
      const { id, restrictions = [] } = action.payload;
      const currentRestrictions = state.restrictionMap;
      const restrictionObj = {};
      for (const restriction of restrictions) {
        restrictionObj[restriction] = true;
      }
      return {
        ...state,
        restrictionMap: {
          ...currentRestrictions,
          [id]: restrictionObj,
        },
      };
    },
  },
  initialItineraryState,
);

const products = handleActions(
  {
    SET_OFFERED_PRODUCTS: (state, action) => {
      return {
        ...state,
        products: { ...state.products, ...action.payload },
      };
    },
    SET_MAX_PRODUCT_PRICE: (state, action) => {
      return {
        ...state,
        maxProductPrice: action.payload,
      };
    },
    SET_CURRENCY: (state, action) => {
      return {
        ...state,
        currency: action.payload,
      };
    },
  },
  {
    products: {},
  },
);

const passengers = handleActions(
  {
    SET_PASSENGERS: (state, action) => {
      let adultIndex = 0;
      let infantIndex = 0;
      const passengers = [];
      const onLapInfants = [];
      const infantToPaxMapping = {};
      for (let i = 0; i < action.payload.length; i++) {
        const passenger = action.payload[i];
        if (!passenger.on_lap_of) {
          passenger['index'] = adultIndex;
          adultIndex++;
          passengers.push(passenger);
        } else {
          passenger['index'] = infantIndex;
          infantToPaxMapping[passenger['on_lap_of']] = passenger.passenger_id;
          infantIndex++;
          onLapInfants.push(passenger);
        }
      }

      passengers.forEach((passenger) => {
        if (infantToPaxMapping[passenger.passenger_id]) {
          passenger['infant_on_lap'] =
            infantToPaxMapping[passenger.passenger_id];
        }
      });

      return {
        ...state,
        listOfPassenger: [...passengers],
        listOfOnLapInfants: [...onLapInfants],
      };
    },
    SET_BAGGAGE_PASSENGERS: (state, action) => {
      const passengers = action.payload.map((passenger, index) => {
        passenger['index'] = index;
        return passenger;
      });
      return {
        ...state,
        listOfBaggagePassengers: [...passengers],
      };
    },
    SET_CURRENT_PASSENGER: (state, action) => ({
      ...state,
      currentPassenger: action.payload,
    }),
    NEXT_PASSENGER: (state, action) => {
      const passengerIndex = state.listOfPassenger.findIndex(
        (passenger) => passenger.passenger_id === action.payload.passenger_id,
      );
      if (passengerIndex >= state.listOfPassenger.length - 1) {
        const currentPassenger = state.listOfPassenger[0];
        return {
          ...state,
          currentPassenger,
        };
      }
      const currentPassenger = state.listOfPassenger[passengerIndex + 1];
      return {
        ...state,
        currentPassenger,
      };
    },
  },
  {
    listOfPassenger: [],
    currentPassenger: 0,
  },
);

const session = handleActions(
  {
    SHOW_SEAT_MAP: (state) => ({
      ...state,
      seatmapShownEventSent: true,
    }),
    SET_LOCALE: (state, action) => ({
      ...state,
      locale: action.payload,
    }),
    SET_ACTIVE_WIDGET: (state, action) => ({
      ...state,
      activeWidget: action.payload,
    }),
    SET_AGENT_BRAND: (state, action) => ({
      ...state,
      agentBrand: action.payload,
    }),
    SET_EDIT_MODE: (state, action) => ({
      ...state,
      editMode: action.payload,
    }),
    SET_VARIANTS: (state, action) => ({
      ...state,
      variants: action.payload,
      variantsReady: true,
    }),
    SET_SHOULD_ABBREVIATE_SEAT_PRICE: (state, action) => ({
      ...state,
      shouldAbbreviateSeatPrice: action.payload,
    }),
    SET_IS_MODAL: (state, action) => ({
      ...state,
      isModal: action.payload,
    }),
    SET_ACCEPTED_PRODUCTS: (state, action) => {
      return {
        ...state,
        acceptedProducts: action.payload,
      };
    },
    SET_THEME: (state, action) => ({
      ...state,
      theme: action.payload,
    }),
    SET_TEXT_OVERRIDES: (state, action) => ({
      ...state,
      textOverrides: action.payload,
    }),
    HAS_SEATS: (state, action) => ({
      ...state,
      hasSeats: action.payload,
    }),
    HAS_BAGS: (state, action) => ({
      ...state,
      hasBags: action.payload,
    }),
    SHOW_MODAL: (state, action) => ({
      ...state,
      modalShown: action.payload,
    }),
    SET_LOADING: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    IS_MOBILE: (state, action) => {
      return {
        ...state,
        mobile: action.payload,
      };
    },
    SHOW_EXIT_MODAL: (state, action) => {
      return {
        ...state,
        exitModal: action.payload,
      };
    },
    ACCEPT_EXIT_REGULATION: (state, action) => {
      return {
        ...state,
        acceptedExitRegulation: action.payload,
      };
    },
    SHOW_CATEGORIES: (state, action) => {
      return {
        ...state,
        showingCategories: action.payload,
      };
    },
    SET_SHOULD_HIDE_PRICE: (state, action) => {
      return {
        ...state,
        shouldHidePrice: action.payload,
      };
    },
    COMPARTMENT_HEIGHT: (state, action) => {
      return {
        ...state,
        parentHeight: action.payload,
      };
    },
    SET_RELATIVE_WIDTH_RATIO: (state, action) => {
      return {
        ...state,
        relativeWidthRatio: action.payload,
      };
    },
    SHOW_DESKTOP_CATEGORIES: (state, action) => {
      return {
        ...state,
        showingDesktopCategories: action.payload,
      };
    },
    SET_SEAT_MAP_STEP: (state, action) => {
      return {
        ...state,
        seatMapStep: action.payload,
      };
    },
    BASKET_CHANGE: (state) => {
      return {
        ...state,
        basketChanging: true,
      };
    },
    FINISH_BASKET_CHANGE: (state) => {
      return {
        ...state,
        basketChanging: false,
      };
    },
    SET_SEGMENT_COLUMNS: (state, action) => {
      return {
        ...state,
        segmentColumns: action.payload,
      };
    },
    SET_SHOW_SEATMAP_VALIDATE_INFANT: (state, action) => {
      return {
        ...state,
        showSeatmapValidateInfantPopup: action.payload,
      };
    },
    SET_SHOW_SEATMAP_VALIDATE_GROUP: (state, action) => {
      return {
        ...state,
        showSeatmapValidateGroupPopup: action.payload,
      };
    },
    SET_PRICE_ADJUSTMENT: (state, action) => {
      return {
        ...state,
        priceAdjustment: action.payload,
      };
    },
    SET_BASKET_UPDATED: (state, action) => {
      return {
        ...state,
        basketUpdated: action.payload,
      };
    },
    SET_PRICE_TEXT_PREPEND: (state, action) => {
      return {
        ...state,
        priceTextPrepend: action.payload,
      };
    },
    SET_UNIQUE_CHARACTERISTICS: (state, action) => {
      return {
        ...state,
        uniqueCharacteristics: action.payload,
      };
    },
    SET_IS_ENHANCED: (state, action) => {
      return {
        ...state,
        isEnhanced: action.payload,
      };
    },
    SHOW_UPSELL_MODAL: (state, action) => {
      return {
        ...state,
        upsellModal: action.payload,
      };
    },
    ACCEPT_UPSELL_MODAL: (state, action) => {
      return {
        ...state,
        acceptedUpsellModal: action.payload,
      };
    },
    SET_SEGMENTS_CURRENT_COMPARTMENT: (state, action) => {
      const { id, compartment } = action.payload;
      const currentCompartments = state.currentCompartments;
      return {
        ...state,
        currentCompartments: {
          ...currentCompartments,
          [id]: compartment,
        },
      };
    },
    SET_SEGMENTS_INITIAL_COMPARTMENT: (state, action) => {
      const { id, compartment } = action.payload;
      const initialCompartments = state.initialCompartments;
      return {
        ...state,
        initialCompartments: {
          ...initialCompartments,
          [id]: compartment,
        },
      };
    },
    SET_IN_FOCUS_COMPARTMENT: (state, action) => {
      return {
        ...state,
        inFocusCompartment: action.payload,
      };
    },
    SET_SHOW_ON_CLOSE_POP_UP: (state, action) => {
      return {
        ...state,
        showOnClosePopUp: action.payload,
      };
    },
    SET_BAGGAGE_DISCLAIMERS: (state, action) => {
      return {
        ...state,
        baggageDisclaimers: action.payload,
      };
    },
    SET_FONT_FAMILY: (state, action) => {
      return {
        ...state,
        fontFamily: action.payload,
      };
    },
  },
  initialSessionState,
);

export default combineReducers({
  session,
  passengers,
  products,
  categories,
  itinerary,
  baggageJSON,
  basket,
  selectedSeat,
  seatDetails,
  localize: localizeReducer,
});

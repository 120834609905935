import React from 'react';
import { BagImage } from './BagImage';
import { DimensionsText } from './DimensionsText';
import { WeightText } from './WeightText';
import { Bag } from './CommonTypes';

export function DesktopBagPresentation({ bag }: { bag: Bag }) {
  const allOffers = [bag];
  const bagDetails = allOffers.sort(
    (o1, o2) => o2.product_details.weight - o1.product_details.weight
  )[0].product_details;

  // If it's a weight-based bag, replace the weight with max quantity from price_and_availability
  // weight-based bags have weight set at a static 1kg, since only 1 weight based bag is allowed, we
  // can use the max quantity from price_and_availability to set the weight
  if (bagDetails.bag_type === 'weight_system') {
    const allAvailabilities = Object.values(bag.price_and_availability || {})
      .flat()
      .filter((entry) => entry.available);

    const maxQuantity = Math.max(
      ...allAvailabilities.map((entry) => entry.quantity || 0)
    );
    bagDetails.weight = maxQuantity;
  }

  const useTotalDimensions = Boolean(
    bagDetails.total_dimensions &&
      (!bagDetails.height || !bagDetails.width || !bagDetails.length)
  );

  return (
    <div
      className="bag-details gr-py-2 gr-px-4 gr-flex gr-justify-center gr-h-full"
      style={{ width: '210px', minHeight: '250px' }}
    >
      <div className="gr-relative gr-flex gr-flex-col gr-justify-between gr-items-center">
        <p className="gr-bag-title-name-text gr-leading-normal gr-text-gray-900 gr-text-lg gr-font-bold gr-not-italic gr-m-0 gr-self-center">
          {bagDetails.display_name}
        </p>
        <div
          className={`gr-flex gr-justify-center ${useTotalDimensions ? 'gr-mb-8' : 'gr-mb-4'}`}
        >
          <BagImage
            smallWidth={true}
            useTotalDimensions={useTotalDimensions}
            bagDetails={bagDetails}
          />
        </div>
        <div className="gr-flex gr-flex-col gr-items-center">
          {useTotalDimensions ? (
            <DimensionsText
              className={
                'gr-bag-max-weight-text gr-leading-normal gr-text-gray-550 gr-text-xs gr-font-semibold gr-not-italic gr-flex gr-justify-center gr-m-0'
              }
              bagDetails={bagDetails}
            />
          ) : null}
          <WeightText bagDetails={bagDetails} extraClassName="gr-m-0" />
        </div>
      </div>
    </div>
  );
}

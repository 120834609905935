const gray_15 = '#DBE4EA';

const base = {
  textPrimary: '#120C80',
  textSecondary: '#4D4B6D',
  buttonText: '#FFFFFF',
  title: '#120C80',
  gray_10: '#EEF4F9',
  gray_15: gray_15,
  gray_20: '#cbd5e0',
  gray_50: '#BBCBD5',
  gray_70: '#718096',
  gray_100: '#4a5568',
  primary: '#120C80',
  secondary: '#4D4B6D',
  link: '#0d7aff',
  actionButtonBorderRadius: '4px',
  buttonBorderRadius: '4px',
  detailContainerDesktopBorderRadius: '8px',
  detailContainerDesktopBorder: '2px solid ' + gray_15,
  detailContainerMobileBorderRadius: '8px',
  detailContainerMobileBorder: '0 solid ' + gray_15,
  legendBorderRadius: '8px',
  legendBorder: '1px solid ' + gray_15,
  alert: '#E06168',
  margin: '0px',
  trigger: {},
  baggageTrigger: {},
  seatMapTrigger: {},
  seats: {
    1: {
      fill: '#9BC8E4',
      stopColor: '#82B4D3',
      borderColor: '#FFFFFF',
    },
    2: {
      fill: '#8FE9FF',
      stopColor: '#7AD2E8',
      borderColor: '#FFFFFF',
    },
    3: {
      fill: '#FFCE51',
      stopColor: '#E7B73D',
      borderColor: '#FFFFFF',
    },
    6: {
      fill: '#15C2C8',
      stopColor: '#13A0A5',
      borderColor: '#FFFFFF',
    },
    5: {
      fill: '#4277FF',
      stopColor: '#305CCE',
      borderColor: '#FFFFFF',
    },
    4: {
      fill: '#AADB72',
      stopColor: '#8EBD59',
      borderColor: '#FFFFFF',
    },
    0: {
      fill: '#AADB72',
      stopColor: '#8EBD59',
      borderColor: '#FFFFFF',
    },
    not_available: {
      fill: '#D9D9D9',
      stopColor: '#D9D9D9',
      borderColor: '#FFFFFF',
    },
    selected_seat: {
      fill: '#5C54BD',
      stopColor: '#372F90',
      borderColor: '#FFFFFF',
    },
  },
  bags: {
    primaryButtonColor: '#4CA7DE',
    primaryButtonText: '#FFFFFF',
    primaryButtonRounding: '8px',
    secondaryButtonColor: '#8D8D8D',
    secondaryButtonText: '#FFFFFF',
    secondaryButtonRounding: '8px',
    addButtonColor: '#4CA7DE',
    addButtonText: '#FFFFFF',
    addButtonRounding: '8px',
    cabinBagColor: '#4CA7DE',
    cabinBagLinearGradient1: '#56BAF6',
    cabinBagLinearGradient2: '#69C6FF',
    cabinBagHandleAndWheels: '#176491',
    checkedBagColor: '#76B761',
    checkedBagLinearGradient1: '#9AC96C',
    checkedBagLinearGradient2: '#9DEB6C',
    checkedBagHandleAndWheels: '#3C6D2C',
    removeBagText: '#76B761',
    fromPriceText: '#846be8',
    routeTextDesktop: '#022694',
    routeArrowDesktop: '#000000',
    routeTextMobile: '#585858',
    routeArrowMobile: '#000000',
  },
};

export default base;
